import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}></MDXTag>

export const _frontmatter = {"templateKey":"current-excavations","title":"Current Excavations","key":"current-excavations","parentKey":"explore-gabii"};

  